@charset "utf-8";
@import "_setting";
@import "_mixin";

.myBlock01 {
	display: flex;
	justify-content:center;
	padding: 50px 0;
	
	@include sphoneP{
		display: block;
		padding: 20px 0;
	}
	>div {
		width: auto;
		text-align: center;
		margin-left: 50px;
		margin-right: 50px;
		@include sphoneP{
			margin: 0 0 50px;
		}
	}
	.logo {
		max-width: 100%;
		@include margin-center;
		margin-bottom: 40px;
		@include sphoneP{
			max-width: 80%;
			margin-bottom: 20px;
		}
		.modBtn01 {
			&:hover{
			}
			&.modBtn01_inlineNarrow{
			}
			&.modBtn01_business{
			}
		}
	}
	img {
	}
	.btn {
		.modBtn01 {
			&:hover{
			}
			&.modBtn01_inlineNarrow{
			}
			&.modBtn01_basic{
			}
		}
	}
}